import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {catchError, EMPTY, Observable, Subject, throwError} from 'rxjs';
import {debounceTime, delay, pluck, switchMap, tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService, LoadingStates } from './loading.service';
import {EventsService} from "./events.service";
import {DownloadService} from "./download.service";

@Injectable({
  providedIn: "root",
})
export class HTTPService {
  ServerURL = `${environment.apiUrl}/v1`;


  IsLoginedChange$ = new Subject<boolean>();
  IsLogined = false;

  UpdateStyle$ = new Subject<any>();
  ScrollToBottom$ = new Subject<any>();

  IsLoadingChange$ = new Subject<boolean>();

  CurrentJob:any = [];

  IsUserChange$ = new Subject<any>();
  User: any = {
    id: null,
    phone: "",
    first_name: "",
    last_name: "",
    createdAt: "",
    profileType: "",
    active:true,
    banned: false,
    needReports: false,
    ownerId:null,
    companyId: null
  };









  headers = new HttpHeaders({
    "Access-Control-Allow-Origin": "*",
  });

  // LoadingSubject: Subject<any> = new Subject();

  constructor(
      private http: HttpClient,
      private router: Router,
      public loadingService: LoadingService,
      private eventsService: EventsService,
      private downloadService: DownloadService
  ) {
    this.IsLoginedChange$.next(false);
    this.IsLoginedChange$.subscribe((val) => {
      //console.log("User", this.User)
      let prevIsLogined = this.IsLogined;
      this.IsLogined = val;
      if (this.IsLogined && !prevIsLogined) {
        this.router.navigate(["/", "base"]);
      } else if (
          this.IsLogined &&
          (this.isInsideAuthModule())
      ) {
        this.router.navigate(["/", "base"]);
      } else  if(this.IsLogined && !this.isInsideRole()){
        //this.router.navigate(["/", "base"]);
      }
      if (!this.IsLogined) {
        this.router.navigate(["auth", "login"]);
      }
    });
  }

  CheckToken() {
    if (localStorage.getItem("token")) {
      // this.isLoading = true;
      this.IsLoadingChange$.next(true);
      if (localStorage.getItem("token")) {
        this.BaseInitByToken(localStorage.getItem("token"));
        this.IsLogined = localStorage.getItem("token") ? true : false;
      }

      this.GetMe().subscribe(
          (res) => {
            this.User = res;
            // this.isLoading = false;
            this.IsUserChange$.next(true);
            this.IsLoginedChange$.next(true);
            this.IsLoadingChange$.next(false);


          },
          (err) => {
            this.ClearSession();
          }
      );
    } else {
      // this.isLoading = false;
      this.ClearSession();
    }
  }

  BaseInitByToken(data:any) {
    if (data) {
      if (!this.headers.has("Authorization")) {
        this.headers = this.headers.append("Authorization", data);
      } else {
        this.headers = this.headers.set("Authorization", data);
      }
    }
  }

  private isInsideAuthModule() {
    return window.location.href.includes("auth") ? true : false;
  }

  private isInsideRole() {
    return window.location.href.includes("company") ||  window.location.href.includes("staff") ||  window.location.href.includes("franchise") ? true : false;
  }


  Login(payload:any) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + "/admin/auth", payload);
    }, this.loadingService.StatesNames.LOGIN)

        ;
  }

  ClearSession() {
    localStorage.setItem("token", "");
    localStorage.removeItem("token");
    localStorage.removeItem("menu");
    localStorage.removeItem("subMenu");
    this.headers = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
    });
    console.log("headers",this.headers)
    if (!this.isInsideAuthModule()) {
      this.router.navigate(["auth", "login"]);
    }
  }

  Logout() {
    return this.RequestHandler(() => {
      return this.http
          .post(this.ServerURL + "/admin/logout", {}, { headers: this.headers })
    }, this.loadingService.StatesNames.LOGOUT);
  }





  GetMe(State:any = this.loadingService.StatesNames.GLOBAL) {
    return this.SendGet("/admin/me", State).pipe();
  }



  RequestHandler(
      runRequest: () => Observable<any>,
      RequestType = LoadingStates.UNKNOWN
  ): Observable<any> {
    let loadingId = this.loadingService.addLoading(RequestType);
    //console.log(`[Request handler] with ID = ${loadingId}`)
    return runRequest().pipe(
        // delay(2000),
        debounceTime(50),
        tap(
            (dataRes) => {
              //console.log(`${loadingId} : DataRes = `, dataRes)
              this.loadingService.removeLoading(loadingId);
            },
            (catchError) => {
              this.loadingService.removeLoading(loadingId);
            }
        ),
        catchError(err=>{
          if(err.status === 401){
            this.ClearSession()
          }








          return throwError(err);



        })
    );
  }

  SendPost(method: string, data:any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendDownloadGet(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURL + method, {
        headers: this.headers,
        responseType: "blob" as "json",
        observe: "response",
      });
    }, type)
        .pipe(tap(this.downloadService.saveResponse2File));

  }

  SendPut(method: string, data:any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.put(this.ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendGet(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURL + method, { headers: this.headers });
    }, type);
  }

  SendGetText(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http
          .get(this.ServerURL + method, {
            headers: this.headers,
            responseType: "text",
          })
          .pipe(
              tap((data) => {
                //console.log(data, typeof(data))
              })
          );
    }, type);
  }

  SendDelete(method: string, data:any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.delete(this.ServerURL + method, {
        headers: this.headers,
      });
    }, type);
  }

  SendPatch(method: string, data:any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.patch(this.ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  getFile(url:string) {
    //console.log("get file", url)
    return this.http
        .get(`${this.ServerURL}`+url, {
          headers: this.headers,
          responseType: "blob" as "json",
          observe: "response",
        })
        .pipe(tap(this.downloadService.saveResponse2File));
  }



  getProfileUrl(profile:string) {
    switch (profile) {
      case 'CONTROL_COMPANY':
        return 'company'
      case 'FRANCHISE':
        return 'franchise'
      case 'STAFF':
        return 'staff'
      default:
        return '';

    }
  }

  getCompanyDesign(type?:string){
    return this.SendGet('/company/design', this.loadingService.StatesNames.GET_DESIGN)
  }

  EditCompanyDesign(data:any,loading = this.loadingService.StatesNames.CONFIRM){
    return this.SendPatch('/company/design', data, loading)
  }

  GetCompanyDictionary(){
    return this.SendGet('/company/dictionary', this.loadingService.StatesNames.GET_DESIGN)
        .pipe(
            tap((res)=>{
              this.CurrentJob = res;
            })
        )
        ;
  }

  capitalizeFirstLetter(string:string) {
    //console.log("capitalizeFirstLetter",string);
    return string?string.charAt(0).toUpperCase() + string.slice(1):null;
    //return string.charAt(0).toUpperCase() + string.slice(1);
  }






}
