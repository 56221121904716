import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotFoundComponent} from "./shared-module/ui/not-found/not-found.component";
const routes: Routes = [
    { path: "", pathMatch: "full", redirectTo: "auth" },
    {
      path: "auth",
      loadChildren: () => {
        return import("./auth-module/auth.module").then(
            (m) => m.AuthModule
        );
      },
    },
    {
      path: "base",
      loadChildren: () => {
        return import("./base-module/base.module").then(
            (m) => m.BaseModule
        );
      },
    },
    {
        path: '404', component: NotFoundComponent,
    },

    {
        path: '**', redirectTo: '404',
    }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
