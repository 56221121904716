import { Component } from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {EventsService} from "./shared-module/services/events.service";
import {HTTPService} from "./shared-module/services/main.service";
import {LoadingService} from "./shared-module/services/loading.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  destroy$ = new Subject();




  constructor(private events: EventsService,
              private toastr: ToastrService,
              private main: HTTPService,
              public loadingService:LoadingService
  ) {
  }
  ngOnInit(): void {
    this.events.IsErrorChange$
        .pipe(
            takeUntil(this.destroy$)
        )
        .subscribe((res) => {
          if (res && this.events.Error.status) {
            this.toastr.error(this.events.Error.text, "Ошибка!", {
              positionClass: "toast-bottom-center",
              titleClass: 'title-toast'

            });
          } else {
            this.toastr.clear();
          }
        })

    this.events.IsSuccessChange$
        .pipe(
            takeUntil(this.destroy$)
        )
        .subscribe((res) => {
          if (res && this.events.Success.status) {
            this.toastr.success(
                this.events.Success.text,
                "Операция выполнена успешно",
                {
                  positionClass: "toast-bottom-center",
                  titleClass: 'title-toast'
                }
            );
          } else {
            this.toastr.clear();
          }
        })
    this.main.CheckToken();

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
